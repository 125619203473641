import React from 'react';
import './stackedBarChart.css'
import {Pie} from 'react-chartjs-2';
import 'react-tippy/dist/tippy.css'
import {Tooltip} from 'react-tippy';

var fs = require('browserify-fs');

const FilterRow = (props) => {
    return (
        <tr>
            <td>
                { props.data.name }
            </td>
            <td>
                { props.data.value }
            </td>
            <td>
                { props.data.percent }
            </td>
        </tr>
    );
}

var chartData2 = {
    labels: ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Pamuk", "Patates", "Yonca", "Diğer"],
    datasets: [
        {
            data: [0,0,0,0,0,0,0,0,0,0],
            hoverBackgroundColor: ['#ffd65b', '#528972', '#6abcc0', '#ab4f95', '#5c65ab', '#f5834e', '#e4e8e3', '#d34e54', '#50d394', '#5C5C5A'],
            hoverBorderColor: ['#dcdcdc', '#dcdcdc', '#dcdcdc', '#dcdcdc', '#dcdcdc', '#dcdcdc', '#dcdcdc', '#dcdcdc', '#dcdcdc', '#dcdcdc'],
            backgroundColor: ['#ffcc33', '#276c4f', '#46acb1', '#96247b', '#343f96', '#f36523', '#dee3dd', '#c9222a', '#25c97a', '#1d1d1b'],
            borderColor: ['#8c8c8b', '#8c8c8b', '#8c8c8b', '#8c8c8b', '#8c8c8b', '#8c8c8b', '#8c8c8b', '#8c8c8b', '#8c8c8b', '#8c8c8b'],
            borderWidth: 1
        }
    ]
};
var elements;

export class Widget extends React.Component {
    constructor(){
        super();
        this.state = {Bunye: "", GubOner: "", SFG: "", SFGtext: "", Alan: "",
            mahalle_alan: "", ilce_alan: "", mahalle_adi: "", ilce_adi: "", il_adi: "", FID:"", filter: "",
            btn_disabled: false,
            URUN :"-", EKIM_TARIH: "-", HASAT_TARIH: "-",URUN2 :"-", EKIM_TARIH2: "-", HASAT_TARIH2: "-",
            Beyan: "",
            filterBunyeValues:["Killi","Orta", "Kumlu", "Cakilli"],
            filterBunyeValuesText:["Killi","Orta", "Kumlu", "Çakıllı"],
            filterOneriValues:["MAP yada TSP, AN, AS, CAN, KNO3 Ürününüze uygun uygulama için danışınız!","DAP, AN, AS, CAN, KNO3 ve K2SO4 + (mikro element gübreleme ihtiyacı) Ürününüze uygun uygulama için danışınız!", "DAP, AN, AS, CAN, KNO3 ve K2SO4 Ürününüze uygun uygulama için danışınız!"],
            filterOneriValuesText:["G1", "G2 + mikro element", "G2"],
            filterSFGvalues:["F1","F2","F3","F4","F5","DogalTarimDisi","SuYuzeyi","YolYerlesim"],
            filterSFGvaluesText: ["Çok Yüksek", "Yüksek", "Orta", "Düşük", "Çok Düşük", "Doğal Tarım Dışı", "Su Yüzeyi", "Yol Yerleşim"],
            filterUrunvalues: ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Pamuk", "Patates", "Yonca", "Diğer"],
            data: []
        }

        this.baseState = this.state;
    }
    componentDidUpdate (){
        if (this.prewLayerContentObj !== this.props.layerContentObj) {
            this.setState(this.baseState);
            let layerContentArray_ = (this.props.layerContentObj.split('<br/>'));
            this.prewLayerContentObj = this.props.layerContentObj;
            var filterName = "";
            var ilAdi = "";
            var ilceAdi = "";
            var mahalleAdi = "";
            var FID = "";
            var mahalleAlan = "";
            let data_ = [];
            for (var i = 0; i < layerContentArray_.length; i++) {
                var attributeNamesStr = layerContentArray_[i].toString().substr(3, layerContentArray_[i].indexOf("</b>") - 5);
                let percentValue = ((parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                    layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1))) * 100) / parseFloat(mahalleAlan)).toString().replace(".",",")
                if (attributeNamesStr !== "") {
                    switch (attributeNamesStr) {
                        case ("ID"): {
                            try {
                                let Id =  layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)

                                fs.exists('/home/' + Id + '.txt',function(exists){
                                    if (exists) {
                                        fs.readFile('/home/' + Id + '.txt', 'utf-8', function (err, data) {
                                            if (err) {
                                                throw err;
                                            }
                                            this.setState({BeyannemeDegerleri: data});
                                        });
                                    }
                                });
                            }
                            catch (err) {
                                console.log(err.message);
                            }
                            break
                        }
                        case ("Bunye"): {
                            this.setState({Bunye: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("GubOner"): {
                            this.setState({GubOner: this.state.filterOneriValuesText[this.state.filterOneriValues.indexOf(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length))]})
                            break
                        }
                        case ("SFG"): {
                            this.setState({SFG: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            this.setState({SFGtext: this.state.filterSFGvaluesText[this.state.filterSFGvalues.indexOf(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length))]})
                            break
                        }
                        case ("Alan"): {
                            this.setState({
                                Alan: (parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                    layerContentArray_[i].indexOf(",") - (layerContentArray_[i].indexOf("</b>") + 1))) ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " da"
                            })
                            break
                        }
                        case ("mahalle_alan"): {
                            mahalleAlan = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1));
                            this.setState({
                                mahalle_alan: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                    layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da"
                            });
                            break
                        }
                        case ("Mahalle"): {
                            mahalleAdi = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length);
                            this.setState({mahalle_adi: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length) + " Mahallesi "})
                            break
                        }
                        case ("ilce_alan"): {
                            this.setState({
                                ilce_alan: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                    layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da"
                            });
                            break
                        }
                        case ("İlçe"): {
                            this.setState({ilce_adi: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            ilceAdi = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length);
                            break
                        }
                        case ("İl"): {
                            this.setState({il_adi: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            ilAdi = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length);
                            break
                        }
                        case ("FID"): {
                            this.setState({FID: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            FID = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length);
                            break
                        }
                        case ("filter"): {
                            switch (layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)) {
                                case("Bunye"): {
                                    this.setState({filter: "Bünye"});
                                    filterName = "Bünye";
                                    break
                                }
                                case("GubOner"): {
                                    this.setState({filter: "Öneri"});
                                    filterName = "Öneri";
                                    break
                                }
                                case("SFG"): {
                                    this.setState({filter: "Verim"});
                                    filterName = "SFG";
                                    break
                                }
                                default:
                                {
                                    break
                                }
                            }
                            break
                        }
                        case ("URUN"): {
                            this.setState({URUN: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("HASAT_TARI"): {
                            this.setState({HASAT_TARIH: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("EKIM_1"): {
                            this.setState({EKIM_TARIH: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("HASAT_1"): {
                            this.setState({HASAT_TARIH: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("URUN_2"): {
                            this.setState({URUN2: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("EKIM_2"): {
                            this.setState({EKIM_TARIH2: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("HASAT_2"): {
                            this.setState({HASAT_TARIH2: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        default: {
                            switch (filterName) {
                                case("Bünye"): {
                                    if (attributeNamesStr.toString().substr(0, attributeNamesStr.indexOf("_")) === mahalleAdi) {
                                        data_.push({
                                            name: this.state.filterBunyeValuesText[this.state.filterBunyeValues.indexOf(attributeNamesStr.toString().substr(attributeNamesStr.indexOf("_") + 1,
                                                attributeNamesStr.length - (attributeNamesStr.indexOf("_") + 1)))],
                                            value: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da",
                                            percent: "%" + percentValue.substr(0, percentValue.indexOf(",") + 2) ,
                                            valueNum: parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",","))});
                                    }
                                    break
                                }
                                case("Öneri"): {
                                    if (attributeNamesStr.toString().substr(0, attributeNamesStr.indexOf("_")) === mahalleAdi) {
                                        data_.push({
                                            name: this.state.filterOneriValuesText[this.state.filterOneriValues.indexOf(attributeNamesStr.toString().substr(attributeNamesStr.indexOf("_") + 1,
                                                attributeNamesStr.length - (attributeNamesStr.indexOf("_") + 1)))],
                                            value: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da",
                                            percent: "%" + percentValue.substr(0, percentValue.indexOf(",") + 2),
                                            valueNum: parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",","))});
                                    }
                                    break
                                }
                                case("SFG"): {
                                    if (attributeNamesStr.toString().substr(0, attributeNamesStr.indexOf("_")) === mahalleAdi) {
                                        data_.push({
                                            name: this.state.filterSFGvaluesText[this.state.filterSFGvalues.indexOf(attributeNamesStr.toString().substr(attributeNamesStr.indexOf("_") + 1,
                                                attributeNamesStr.length - (attributeNamesStr.indexOf("_") + 1)))],
                                            value: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da",
                                            percent: "%" + percentValue.substr(0, percentValue.indexOf(",") + 2),
                                            valueNum: parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",","))});
                                    }
                                    break
                                }
                                default:
                                {
                                    break
                                }
                            }
                            break
                        }
                    }
                }
            }
            this.getChartMahalle(ilAdi, ilceAdi,mahalleAdi);
        }
        elements = document.getElementsByClassName('barValue');
        for(i = 0; i < elements.length; i++)
        {
            /*let widthValue= parseFloat(elements[i].textContent.substring(0, elements[i].textContent.indexOf('%')));
            if (widthValue<2){
                elements[i].parentElement.parentElement.style.width = 2 + '%';
            }
            else {
                elements[i].parentElement.parentElement.style.width = elements[i].textContent.substring(0, widthValue - 1) + '%';
            }*/
            let percentValue = 0;
            if(chartData2.datasets[0].data[i] !== "NaN")
            {
                percentValue = chartData2.datasets[0].data[i];
            }
            elements[i].parentElement.parentElement.style.width=percentValue+'%';
            elements[i].parentElement.style.display="block";
        }
    }

    getChartMahalle(il, ilce, mahalle) {
        let mahalleIndex = 0;
        let ilceIndex = 0;
        let ilIndex = 0;
        let urunIndex = 0;
        let urun2Index = 0;
        let alanIndex = 0;
        let firstValue = true;
        let filterUrunvaluesArray = ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Pamuk", "Patates", "Yonca", "Diğer"];
        this.xml = new XMLHttpRequest();
        this.xml.open('GET', "map.kml", false);
        this.xml.send();
        let xmlData = this.xml.responseXML;
        if (!xmlData) {
            xmlData = (new DOMParser()).parseFromString(this.xml.responseText, 'text/xml');
        }
        var Folder = xmlData.getElementsByTagName("Folder");
        var Placemark = Folder[0].getElementsByTagName("Placemark");
        let urunler = [0,0,0,0,0,0,0,0,0,0]
        for (var j = 0; j < Placemark.length; j++) {
            var ExtendedData = Placemark[j].getElementsByTagName("ExtendedData");
            var SchemaData = ExtendedData[0].getElementsByTagName("SchemaData");
            var SimpleData = SchemaData[0].getElementsByTagName("SimpleData");
            if (firstValue) {
                for (var k = 0; k < SimpleData.length; k++) {
                    if (SimpleData[k].attributes.name.nodeValue === "Mahalle") {
                        mahalleIndex = k;
                    }
                    else if (SimpleData[k].attributes.name.nodeValue === "İlçe") {
                        ilceIndex = k;
                    }
                    else if (SimpleData[k].attributes.name.nodeValue === "İl") {
                        ilIndex = k;
                    }
                    else if (SimpleData[k].attributes.name.nodeValue === "URUN") {
                        urunIndex = k;
                    }
                    else if (SimpleData[k].attributes.name.nodeValue === "URUN_2") {
                        urun2Index = k;
                    }
                    else if (SimpleData[k].attributes.name.nodeValue === "Alan") {
                        alanIndex = k;
                    }
                }
                firstValue = false;
            }
            if(SimpleData[ilIndex].innerHTML === il || SimpleData[ilceIndex].innerHTML === ilce || SimpleData[mahalleIndex].innerHTML === mahalle)
            {
                let beyan = SimpleData[urunIndex].innerHTML;
                if(beyan.includes(" ") && beyan !== "Şeker Pancarı")
                {
                    beyan = beyan.split(" ")[1]
                }
                if (filterUrunvaluesArray.indexOf(beyan) >= 0) {
                    urunler[filterUrunvaluesArray.indexOf(beyan)] += parseFloat(SimpleData[alanIndex].innerHTML);
                }
                beyan = SimpleData[urun2Index].innerHTML;
                if (filterUrunvaluesArray.indexOf(beyan) >= 0) {
                    urunler[filterUrunvaluesArray.indexOf(beyan)] += parseFloat(SimpleData[alanIndex].innerHTML);
                }
            }
        }
        let urunSiralamaNo =[];
        let tempUrunler = [];
        for(var i = 0; i<urunler.length;i++)
        {
            tempUrunler.push(urunler[i]);
        }
        tempUrunler.sort(function compareNumbers(a, b) {
            return a - b;
        });

        for(i = 0; i < tempUrunler.length; i++)
        {
            urunSiralamaNo.push(urunler.indexOf(tempUrunler[i]));
        }

        let tempfilterUrunvalues = filterUrunvaluesArray;
        for(i = 0; i <=4; i++) {
            if(urunSiralamaNo[i] !== 9){
                urunler[9] += urunler[urunSiralamaNo[i]];
                if(urunler[urunSiralamaNo[i]] > 0) {
                    tempfilterUrunvalues[9] += ", ";
                    tempfilterUrunvalues[9] += tempfilterUrunvalues[urunSiralamaNo[i]];
                    urunler[urunSiralamaNo[i]] = 0;
                }
            }
        }
        tempfilterUrunvalues[9] = (tempfilterUrunvalues[9].split(", ").sort()).toString();
        tempfilterUrunvalues[9] = tempfilterUrunvalues[9].replace(/,/g, ", ");
        this.setState({filterUrunvalues: tempfilterUrunvalues});


        let total = 0;
        for (i = 0; i < urunler.length; i++) {
            total += urunler[i];
        }
        for (i = 0; i < urunler.length; i++) {
            chartData2.datasets[0].data[i] = (Math.floor(((urunler[i] * 100) / total)*100)/100).toFixed(2);
        }
        chartData2.labels[9] = tempfilterUrunvalues[9];
    }

    render() {
        var prewLayerContentObj;
        let counter = 0;
        var rows = this.state.data.map(filtre => {
            return <FilterRow
                key = {
                    counter++
                }
                data = {
                    filtre
                }
            />
        })

        return(
            <div id="widget" className="full-height col-12">
                <input type="hidden" id="hiddenInput" value={this.props.layerContentObj}/>

                <div className={"firstTable"}>
                    <table className="table table-hover table-dark">
                        <thead>
                        <tr>
                            <th colSpan="2">
                                <span className={"tableTitle"}>{this.state.mahalle_adi + "Ürün Dağılımı:"}</span>
                            </th>
                        </tr>
                        <tr className={"Chart"}>
                            <td>
                                <div >
                                        <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[0] + ": " + "%" + chartData2.datasets[0].data[0]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[0]}%</span>
                                            </Tooltip>
                                        </span>
                                    <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[1] + ": " + "%" + chartData2.datasets[0].data[1]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[1]}%</span>
                                            </Tooltip>
                                        </span>
                                    <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[2] + ": " + "%" + chartData2.datasets[0].data[2]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[2]}%</span>
                                            </Tooltip>
                                        </span>
                                    <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[3] + ": " + "%" + chartData2.datasets[0].data[3]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[3]}%</span>
                                            </Tooltip>
                                        </span>
                                    <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[4] + ": " + "%" + chartData2.datasets[0].data[4]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[4]}%</span>
                                            </Tooltip>
                                        </span>
                                    <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[5] + ": " + "%" + chartData2.datasets[0].data[5]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[5]}%</span>
                                            </Tooltip>
                                        </span>
                                    <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[6] + ": " + "%" + chartData2.datasets[0].data[6]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[6]}%</span>
                                            </Tooltip>
                                        </span>
                                    <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[7] + ": " + "%" + chartData2.datasets[0].data[7]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[7]}%</span>
                                            </Tooltip>
                                        </span>
                                    <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[8] + ": " + "%" + chartData2.datasets[0].data[8]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[8]}%</span>
                                            </Tooltip>
                                        </span>
                                    <span className="block">
                                            <Tooltip title={this.state.filterUrunvalues[9] + ": " + "%" + chartData2.datasets[0].data[9]} arrow="true" position="top" trigger="mouseenter">
                                                <span className="barValue value">{chartData2.datasets[0].data[9]}%</span>
                                            </Tooltip>
                                        </span>
                                </div>
                            </td>
                        </tr>
                        </thead>
                    </table>
                </div>
                <div>
                    <table className="table table-hover table-dark">
                        <thead>
                        <tr>
                            <th colSpan="2">
                                <span className={"tableTitle"}>Parsel Değerleri:</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Alan:</td>
                            <td>{this.state.Alan}</td>
                        </tr>
                        <tr>
                            <td>Ürün:</td>
                            <td>{this.state.URUN}</td>
                        </tr>
                        <tr>
                            <td>Ekim Tarihi:</td>
                            <td>{this.state.EKIM_TARIH}</td>
                        </tr>
                        <tr>
                            <td>Hasat Tarihi:</td>
                            <td>{this.state.HASAT_TARIH}</td>
                        </tr>
                        <tr>
                            <td>Ürün 2:</td>
                            <td>{this.state.URUN2}</td>
                        </tr>
                        <tr>
                            <td>Ekim Tarihi 2:</td>
                            <td>{this.state.EKIM_TARIH2}</td>
                        </tr>
                        <tr>
                            <td>Hasat Tarihi 2:</td>
                            <td>{this.state.HASAT_TARIH2}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}


/*<div className={"firstTable"}>
                    <table className="table table-hover table-dark">
                        <thead>
                        <tr>
                            <th colSpan="2">
                                <span className={"tableTitle"}>{this.state.mahalle_adi + "Ürün Yüzde Dağılımı:"}</span>
                            </th>
                        </tr>
                        <tr className={"Chart"}>
                            <td>
                                <Pie data={chartData2} redraw={true} options={optionsPercentPie}
                                />
                            </td>
                        </tr>
                        </thead>
                    </table>
                </div>*/

